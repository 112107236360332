/* bootstrap variable and class global override */
$form-check-input-checked-color: #00ba82 !default;
$form-check-input-checked-bg-color: #fff !default;
$form-check-input-checked-border-color: #00ba82 !default;

.form-check-input[type='radio'] {
    margin: 0 !important;
    flex: none;
}

.form-check-input[type='checkbox'] {
    flex: none;
}

.fs-small {
    font-size: var(--size-14) !important;
}

.cost-field {
    min-width: 62px;
}