:root {
    /*
    * Header
    */
    --height-header: 64px;

    /*
    * Colors
    */
    --blue: #3b63f3;
    --light-blue01: #dfecf8;
    --light-blue02: #f8f5ff;
    --dark-blue: #050a31;
    --dark-grey: #798590;
    --dark-grey02: #4f575f;
    --dark-green: #2a6d59;
    --dark-yellow: #e1a325;
    --light-grey02: #bebebe;
    --grey-02: #d3d3d3;

    --light-purple-01: #f9fbff;
    --light-purple-02: #f5f8ff;
    --light-green: #1bcf990d;
    --light-yellow: #ffd34e0d;
    --light-purple-03: #dee5ff;

    --light-yellow-01: #fff4d1;

    --btn-green: #00ba82;
    --btn-yellow: #ffd34e;

    --light-green-01: #e0fff6;
    --green: #1bcf99;

    --space-01: #455a64;

    --grey: #85868b;
    --grey-03: #b3b5b9;
    --light-purple-04: #fafbfe;
    --light-green: #f2fffb;
    --grey-06: rgba(249, 249, 250, 1);
    --grey-04: #d9dbdf;

    /*
    * Font weights
    */
    --bold: 700;
    --semibold: 600;
    --medium: 500;
    --regular: 400;

    /*
    * Font sizes
    */
    --base-font: 16px;

    --size-40: calc((var(--base-font) * 2.5) + 0.005625vw);
    --size-30: calc((var(--base-font) * 1.875) + 0.005625vw);
    --size-26: calc((var(--base-font) * 1.625) + 0.005625vw);
    --size-22: calc((var(--base-font) * 1.375) + 0.005625vw);
    --size-20: calc((var(--base-font) * 1.25) + 0.005625vw);
    --size-18: calc((var(--base-font) * 1.125) + 0.005625vw);
    --size-16: calc(var(--base-font) + 0.005625vw);
    --size-14: calc((var(--base-font) * 0.875) + 0.005625vw);
    --size-12: calc((var(--base-font) * 0.75) + 0.005625vw);

    /* Desktop Fonts */
    --h1-font-size: 40px;
    --h1-font-weight: 600;

    --h2-font-size: 30px;
    --h2-font-weight: 600;

    --h3-font-size: 20px;
    --h3-font-weight: 600;

    --h4-font-size: 16px;
    --h4-font-weight: 600;

    --h5-font-size: 16px;
    --h5-font-weight: 500;

    --p1-font-size: 16px;
    --p1-font-weight: 400;

    --p2-font-size: 14px;
    --p2-font-weight: 400;

    --p3-font-size: 12px;
    --p3-font-weight: 400;

    --link-font-size: 16px;
    --link-font-weight: 500;

    --caps-font-size: 16px;
    --caps-font-weight: 600;

    /* Tablet Fonts */
    @media screen and (max-width: 767.98px) {
        --h1-font-size: 32px;
        --h2-font-size: 26px;
        --h3-font-size: 18px;
        --h4-font-size: 14px;
        --h5-font-size: 14px;
        --p1-font-size: 14px;
        --p2-font-size: 12px;
        --p3-font-size: 10px;
        --link-font-size: 14px;
        --caps-font-size: 14px;
    }

    /* Mobile Fonts */
    @media screen and (max-width: 575.98px) {
        --h1-font-size: 28px;
        --h2-font-size: 22px;
        --h3-font-size: 16px;
        --h4-font-size: 14px;
        --h5-font-size: 14px;
        --p1-font-size: 14px;
        --p2-font-size: 12px;
        --p3-font-size: 10px;
        --link-font-size: 14px;
        --caps-font-size: 12px;
    }
}
