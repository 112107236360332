body {
    color: var(--dark-blue) !important;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.true-diem-btn {
    border-radius: 2.375rem !important;
    height: 4.063rem;
    width: 15.125rem;
}

a {
    text-decoration: none !important;
}

/* scroller */
@media screen and (min-width: 991.98px) {
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background: #d3d3d3;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #85868b;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #999;
    }
}
/*--- shimmer effect -- **/

.shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 100%;
    display: inline-block;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

/* carousel */
.carousel-control-next-icon {
    background-image: none !important;
    background-color: var(--light-grey02);
    width: 32px !important;
    height: 32px !important;

    &::before {
        content: '\F285';
        position: relative;
        top: 3px;
        left: 1px;
        color: black;
        font-family: 'bootstrap-icons';
        font-size: 18px;
        font-weight: 600;
        width: 20px;
        height: 20px;
    }
}

.carousel-control-prev-icon {
    background-image: none !important;
    background-color: var(--light-grey02);
    width: 32px !important;
    height: 32px !important;

    &::before {
        content: '\F284';
        position: relative;
        top: 3px;
        left: -1px;
        color: black;
        font-family: 'bootstrap-icons';
        font-size: 18px;
        font-weight: 600;
        width: 20px;
        height: 20px;
    }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    filter: invert(1);
    border-radius: 50%;
    border: 1px solid var(--grey-03);
}

.carousel-control-next,
.carousel-control-prev {
    width: 32px !important;
}

/* chart */

/* calendar */

.ngb-dp-weekday {
    color: black !important;
    font-style: normal !important;
    font-weight: 600;
}

.ngb-dp-weekdays {
    border-bottom: 0 !important;
}

/*--------*/

.dni-typeahead-container {
    height: 300px;
    overflow-y: auto;
}
