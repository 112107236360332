@import '/src/app/_variables.scss';
@font-face {
    font-family: Poppins;
    src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

html,
body {
    height: 100%;
    overflow: hidden;
}
body {
    margin: 0;
    font-family: Poppins, Roboto, 'Helvetica Neue', sans-serif !important;
}

/* Importing Bootstrap SCSS file. */
@import './custom-styles/custom.scss';
@import '~bootstrap/scss/bootstrap';
@import url('./custom-styles/global.scss');
@import '~bootstrap-icons/font/bootstrap-icons.css';
/* import chartist styles */
@import '~chartist/dist/index.css';

/*
 *
 * Tooltip */
.tooltip.show {
    opacity: 1;
}

.tooltip {
    .tooltip-inner {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 17px 11px 27px 16px;
        max-width: 300px;

        text-align: start;
        font-size: 1rem;
        background-color: white;
        color: black;
    }

    .tooltip-arrow::before {
        border-bottom-color: white;
    }
}

// TODO: once done this will be at global level instead of being class
.responsive-font {
    h1 {
        font-size: var(--h1-font-size);
        font-weight: var(--h1-font-weight);
    }

    h2 {
        font-size: var(--h2-font-size);
        font-weight: var(--h2-font-weight);
    }

    h3 {
        font-size: var(--h3-font-size);
        font-weight: var(--h3-font-weight);
    }

    h4 {
        font-size: var(--h4-font-size);
        font-weight: var(--h4-font-weight);
    }

    h5 {
        font-size: var(--h5-font-size);
        font-weight: var(--h5-font-weight);
    }

    p,
    .p1,
    label,
    input {
        font-size: var(--p1-font-size);
        font-weight: var(--p1-font-weight);
    }

    p.p2,
    .p2 {
        font-size: var(--p2-font-size);
        font-weight: var(--p2-font-weight);
    }

    p.p3 {
        font-size: var(--p3-font-size);
        font-weight: var(--p3-font-weight);
    }

    a {
        font-size: var(--link-font-size);
        font-weight: var(--link-font-weight);
    }

    .caps {
        font-size: var(--caps-font-size);
        font-weight: var(--caps-font-weight);
    }
}
